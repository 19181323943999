import React, { useEffect, useState } from "react";
import { isEmail } from "../../utils";
import { clearFirebaseResponse, sendResetPwEmail } from "../../actions/login";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import { showNotifier } from "../../actions/ui";
import { NotifierBgColor } from "../../models";
import dispatch from "../../middleware";
import { Link } from "react-router-dom";
import Logo from "../../images/logo-dark.svg";
import { renderFirebaseResponse } from "./utils";
import LeftArrow from "../../images/left-arrow.svg";

async function handleForgotPassword(email: string, storeDispatch: any) {
  if (isEmail(email)) {
    await sendResetPwEmail(email, storeDispatch);
  }
}

export default function ForgotPassword() {
  const [email, updateEmail] = useState("");
  const firebaseResponse = useSelector(
    (state: ReduxState) => state.login.firebaseResponse
  );
  const loading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );
  const storeDispatch = useDispatch();

  useEffect(() => {
    if (firebaseResponse.type !== "") {
      dispatch(storeDispatch, clearFirebaseResponse());
    }
  }, []);

  useEffect(() => {
    const bgColor =
      firebaseResponse.type === "SUCCESS"
        ? NotifierBgColor.SUCCESS
        : NotifierBgColor.ERROR;
    dispatch(
      storeDispatch,
      showNotifier(bgColor, firebaseResponse.response.message)
    );
  }, [firebaseResponse.type !== ""]);

  return (
    <div className="auth-page-wrapper">
      <div className="auth-small-card text-center">
        <div>
          <img src={Logo} alt="Logo" width="100px" />
        </div>
        <p className="subheading text-center mt-5 mb-2">Forgot your password</p>
        <p className="primary-color fs-12">
          Just enter the email address that you have used to create Refyne
          account. We will send a reset link.
        </p>
        <div className="form-signin-label-group mb-3 mt-4 text-left">
          <input
            id="email"
            type="email"
            className="form-control input-login"
            placeholder="Enter your E-mail"
            value={email}
            onChange={(e) => updateEmail(e.target.value)}
            autoComplete="off"
          />
          <label htmlFor="email">Enter your E-mail</label>
        </div>
        {firebaseResponse.type !== "" &&
          renderFirebaseResponse(firebaseResponse.response.message)}
        <button
          disabled={!isEmail(email) || loading}
          className={"btn primary-button"}
          onClick={() => handleForgotPassword(email, storeDispatch)}
        >
          {loading ? "Please wait..." : "Request Link"}
        </button>
        <br />
        <br />
        <br />
        <Link to={"/?signIn=true"}>
          <p className="primary-color m-0 fs-14">
            <img src={LeftArrow} alt="Arrow" className="mr-2" width="15px" />{" "}
            Back to{" "}
            <span className="secondary-color cursor-pointer">Sign In</span>
          </p>
        </Link>
      </div>
    </div>
  );
}
