import { FieldName, IValidation } from "../../models";
import { getRandomId, isEmail } from "../../utils";
import React from "react";
import { Link } from "react-router-dom";
import LeftArrow from "../../images/left-arrow.svg";
import Eye from "../../images/eye.svg";
import CloseEye from "../../images/close-eye.svg";

export function validateLogin(
  email: string,
  password: string,
  reEnteredPw: string,
  isSignIn: boolean
): IValidation {
  const validation: IValidation = {
    status: false,
    msg: "",
    type: "",
  };
  if (!isEmail(email)) {
    validation.msg = "Invalid";
    validation.type = FieldName.EMAIL;
  } else if (password.trim() === "") {
    validation.msg = "Invalid";
    validation.type = FieldName.PASSWORD;
  } else if (!isSignIn && reEnteredPw.trim() === "") {
    validation.msg = "Invalid";
    validation.type = FieldName.RE_ENTER_PW;
  } else if (!isSignIn && password !== reEnteredPw) {
    validation.msg = "Password did not match";
    validation.type = FieldName.RE_ENTER_PW;
  } else {
    validation.status = true;
  }
  return validation;
}

export function renderSignUpText(
  updateIsSignIn: (val: boolean) => void,
  isSignIn: boolean
) {
  return (
    <p className="primary-color fs-14">
      <img src={LeftArrow} alt="Arrow" className="mr-2" width="15px" />
      {isSignIn ? "Doesn't have an account? " : "Already have an account? "}
      <span
        className="cursor-pointer secondary-color"
        onClick={() => updateIsSignIn(!isSignIn)}
      >
        {isSignIn ? <span>Sign up</span> : <span>Sign in</span>}
      </span>
    </p>
  );
}

export function renderWelcomeText(isSignIn: boolean) {
  return (
    <div className="welcome-text pb-4">
      {isSignIn
        ? "Become part of the growing network of employers who reflect an employee-first approach and help in building financial resilience of their workforce."
        : "Congratulations on taking a step in the direction of building a productive and stress-free workforce!"}
    </div>
  );
}

export function renderSubmit(
  handleSubmit: (e: any) => void,
  isSignIn: boolean
) {
  return (
    <button
      className="btn primary-button mt-3"
      onClick={handleSubmit}
      type="submit"
    >
      {isSignIn ? "Sign in" : "Sign up"}
    </button>
  );
}

export function renderForgetPassword() {
  return (
    <div className="login-line-height text-center py-3">
      <Link
        className="cursor-pointer font-size-small secondary-color"
        to={"/forgotPassword"}
      >
        Forgot your password?
      </Link>
    </div>
  );
}
export function renderEmail(
  email: string,
  handleChange: (fieldName: string, value: string | number) => void,
  errorMsg: string
) {
  return (
    <div className="form-signin-label-group">
      <input
        type="email"
        id="inputEmail"
        className="form-control input-login"
        placeholder="Email address"
        value={email}
        onChange={(e) => handleChange(FieldName.EMAIL, e.target.value)}
      />
      {errorMsg.trim() !== "" && (
        <div className={"login-error-text"}>{errorMsg}</div>
      )}
      <label htmlFor="inputEmail">Email address</label>
    </div>
  );
}
export function renderPassword(
  password: string,
  handleChange: (fieldName: string, value: string | number) => void,
  label: string,
  isPasswordVisible: boolean,
  setPasswordVisibleStatus: (v: boolean) => void,
  fieldName: string,
  errorMsg: string
) {
  const id = `inputPassword-${getRandomId()}`;
  return (
    <div className="form-signin-label-group position-relative">
      <input
        type={isPasswordVisible ? "text" : "password"}
        id={id}
        className="form-control input-login"
        placeholder="Password"
        value={password}
        onChange={(e) => handleChange(fieldName, e.target.value)}
      />
      <label htmlFor={id}>{label}</label>
      {errorMsg.trim() !== "a" && (
        <div className={"login-error-text"}>{errorMsg}</div>
      )}
      <img
        src={isPasswordVisible ? CloseEye : Eye}
        alt="eye"
        width="15px"
        className="eye"
        onClick={() => setPasswordVisibleStatus(!isPasswordVisible)}
      />
    </div>
  );
}
export function renderFirebaseResponse(
  errorMsg: string,
  isCustomErrorMesageRequired: boolean = true
) {
  let message = errorMsg;
  if (
    errorMsg.startsWith("Missing password requirements") &&
    isCustomErrorMesageRequired
  ) {
    message =
      "Password Expired. Please click on below Forget Password link to reset it.";
  }
  return (
    <div className={"login-error-text text-center firebase-error mb-3"}>
      {message}
    </div>
  );
}
